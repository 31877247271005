import bilde7 from "../img/bilde7.JPG"
import bilde9 from "../img/bilde9.JPG"
import bilde10 from "../img/bilde10.JPG"
import bilde11 from "../img/bilde11.JPG"
import bilde13 from "../img/bilde13.JPG"
import logo from "../img/logo.png"
export default function Example() {
  return (
    <div className="bg-white">
      <header className="absolute inset-x-0 top-0 z-50">
        <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
          <div className="flex lg:flex-1">
              <div className='flex flex-row'>
              <img
                className="h-14 w-auto"
                src={logo}
                alt=""
              />
              <div className='flex flex-col'>
              <p className='mt-1 text-base md:text-xl font-bold'>Kristiansen mur og betong service A/S</p>
              <p className='text-sm md:text-lg font-semibold text-center'>`Vær lur, bruk mur`</p>
              </div>
              </div>
          </div>
        </nav>
      </header>
      <main>
        <div className="relative isolate">
          <svg
            className="absolute inset-x-0 top-0 -z-10 h-[64rem] w-full stroke-gray-200 [mask-image:radial-gradient(32rem_32rem_at_center,white,transparent)]"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84"
                width={200}
                height={200}
                x="50%"
                y={-1}
                patternUnits="userSpaceOnUse"
              >
                <path d="M.5 200V.5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
              <path
                d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect width="100%" height="100%" strokeWidth={0} fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)" />
          </svg>
          <div
            className="absolute left-1/2 right-0 top-0 -z-10 -ml-24 transform-gpu overflow-hidden blur-3xl lg:ml-24 xl:ml-48"
            aria-hidden="true"
          >
            <div
              className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
              style={{
                clipPath:
                  'polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)',
              }}
            />
          </div>
          <div className="overflow-hidden">
            <div className="mx-auto max-w-7xl px-6 pb-32 pt-36 sm:pt-60 lg:px-8 lg:pt-32">
              <div className="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
                <div className="lg:flex flex-col">
                <div className="w-full max-w-xl lg:shrink-0 xl:max-w-2xl">
                  <h2 className="text-xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                  Trond Vidar Kristiansen har jobbet som murer i over 40 år. Han stiftet Kristiansen mur og betong service A/S i 2016.
                  </h2>
                  <p className="relative mt-6 text-lg leading-8 text-gray-600 sm:max-w-md lg:max-w-none">
                  Kristiansen mur og betong service A/S er et far-sønn firma som bryr seg om kvalitet. Vi har som mål å alltid utføre jobben slik at vi oppnår best mulig resultat.
                   Vi utfører jobber i <b>Østfold, Akershus og Oslo</b>.
                  </p>
                </div>
                <div className="mt-7 px-4 py-5 sm:p-6 bg-gray-900 rounded-xl">{<div className=" justify-center sm:flex ">
                <div className="mb-4 flex-shrink-0 sm:mb-0 sm:mr-4">
                </div>
                <div className=''>
                <div className="flex flex-col gap-x-2.5">
                <p className="inline-flex justify-center font-bold text-white text-lg items-center border-b-2 border-transparent font-bold">
                Kontakt Oss  </p>
                <p className='text-lg text-center font-bold text-gray-200 '>Email: <a href="mailto:trond@kmbservice.no" className="inline-flex justify-center font-bold text-gray-200 text-lg items-center border-b-2 border-transparent font-bold hover:border-orange-900 hover:text-black-700">trond@kmbservice.no</a></p>
                <p className="inline-flex justify-center font-bold text-gray-200 text-lg items-center border-b-2 border-transparent font-bold">Telefon: (+47) 91753526</p>
                </div>
                </div>
                </div>}</div>
                </div>
                <div className="-mx-8 md:mx-auto mt-14 flex justify-end gap-8 sm:-mt-44 sm:justify-start sm:pl-20 lg:mt-0 lg:pl-0">
                  <div className="ml-auto w-44 flex-none space-y-8 pt-32 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80">
                    <div className="relative">
                      <img
                        src={bilde7}
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                  </div>
                  <div className="mr-auto w-44 flex-none space-y-8 sm:mr-0 sm:pt-52 lg:pt-36">
                    <div className="relative">
                      <img
                        src={bilde9}
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                    <div className="relative">
                      <img
                        src={bilde11}
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                  </div>
                  <div className="w-44 flex-none space-y-8 pt-32 sm:pt-0">
                    <div className="relative">
                      <img
                        src={bilde10}
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                    <div className="relative">
                      <img
                        src={bilde13}
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}
