import { WrenchScrewdriverIcon } from '@heroicons/react/20/solid'
import bilde1 from "../img/bilde1.jpg"
import bilde3 from "../img/bilde3.JPG"

const features = [
  {
    name: 'Forskalings og støpe arbeid',
    icon: WrenchScrewdriverIcon,
  },
  {
    name: 'Støping av gulv, trapp og støttemurer',
    icon: WrenchScrewdriverIcon,
  },
  {
    name: 'Plate på mark',
    icon: WrenchScrewdriverIcon,
  },
  {
    name: 'Betong-rehabilitering',
    icon: WrenchScrewdriverIcon,
  },
  {
    name: 'Armering',
    icon: WrenchScrewdriverIcon,
  },
  {
    name: 'Radon',
    icon: WrenchScrewdriverIcon,
  },
]

const features2 = [
  {
    name: 'Grunnmur i tradisjonellblokker og isolasjonssystemer',
    icon: WrenchScrewdriverIcon,
  },
  {
    name: 'Fasadepuss med/uten isolasjon',
    icon: WrenchScrewdriverIcon,
  },
  {
    name: 'Ulike pusstyper',
    icon: WrenchScrewdriverIcon,
  },
  {
    name: 'Skifer og granitt arbeid',
    icon: WrenchScrewdriverIcon,
  },
  {
    name: 'Teglsteins-muring',
    icon: WrenchScrewdriverIcon,
  },
  {
    name: 'BAC beton cire',
    icon: WrenchScrewdriverIcon,
  },
  {
    name: 'Piper og ildsteder',
    icon: WrenchScrewdriverIcon,
  },
]

export default function Example() {
  return (
    <main>
      <div className='flex flex-col items-center'>
        <h1 className='font-bold text-3xl'>Våre tjenester</h1>
        <p className='text-lg text-center text-gray-600'>Vi utfører alt innen Mur og betong, under er det noen eksempler på tjenster vi tilbyr:</p>
      </div>
    <div className="overflow-hidden bg-white py-16">
      <div className="mx-auto max-w-7xl px-6 lg:px-40">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:ml-auto lg:pl-4 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-2xl font-semibold leading-7 text-indigo-600">Betong arbeid</h2>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline text-lg font-semibold text-gray-900">
                      <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true" />
                      {feature.name}
                    </dt>{' '}
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <div className="flex items-start justify-center md:justify-end lg:order-first">
            <img
              src={bilde1}
              alt="Product screenshot"
              className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
              width={2432}
              height={1442}
            />
          </div>
        </div>
      </div>
    </div>
    <div className="overflow-hidden bg-white py-16">
    <div className="mx-auto max-w-7xl px-6 lg:px-8">
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
        <div className="lg:ml-auto lg:pl-4 lg:pt-4">
          <div className="lg:max-w-lg">
            <h2 className="text-2xl font-semibold leading-7 text-indigo-600">Mur arbeid</h2>
            <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
              {features2.map((feature) => (
                <div key={feature.name} className="relative pl-9">
                  <dt className="inline text-lg font-semibold text-gray-900">
                    <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true" />
                    {feature.name}
                  </dt>{' '}
                </div>
              ))}
            </dl>
          </div>
        </div>
        <div className="flex items-center justify-center md:justify-end lg:order-first">
          <img
            src={bilde3}
            alt="Product screenshot"
            className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
            width={2432}
            height={1442}
          />
        </div>
      </div>
    </div>
  </div>
  </main>
  )
}

