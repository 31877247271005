import bilde2 from '../img/bilde2.jpg';
import bilde4 from '../img/bilde4.JPG';
import bilde5 from '../img/bilde5.JPG';
import bilde6 from '../img/bilde6.JPG';
import bilde8 from '../img/bilde8.JPG';
import bilde12 from '../img/bilde12.JPG';


export default function Example() {
  return (
    <main>
        <div className="container mx-auto px-5 py-2 lg:px-32 lg:pt-24">
        <div className="-m-1 flex flex-wrap md:-m-2">
            <div className="flex w-1/2 flex-wrap">
            <div className="w-1/2 p-1 md:p-2">
                <img
                alt="gallery"
                className="block h-full w-full rounded-lg object-cover object-center"
                src={bilde2} />
            </div>
            <div className="w-1/2 p-1 md:p-2">
                <img
                alt="gallery"
                className="block h-full w-full rounded-lg object-cover object-center"
                src={bilde4} />
            </div>
            <div className="w-full p-1 md:p-2">
                <img
                alt="gallery"
                className="block h-full w-full rounded-lg object-cover object-center"
                src={bilde5} />
            </div>
            </div>
            <div className="flex w-1/2 flex-wrap">
            <div className="w-full p-1 md:p-2">
                <img
                alt="gallery"
                className="block h-full w-full rounded-lg object-cover object-center"
                src={bilde6} />
            </div>
            <div className="w-1/2 p-1 md:p-2">
                <img
                alt="gallery"
                className="block h-full w-full rounded-lg object-cover object-center"
                src={bilde8} />
            </div>
            <div className="w-1/2 p-1 md:p-2">
                <img
                alt="gallery"
                className="block h-full w-full rounded-lg object-cover object-center"
                src={bilde12} />
            </div>
            </div>
        </div>
        </div>
  </main>
  )
}

