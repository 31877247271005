import './App.scss';
import Top from './components/top';
import Tjenester from './components/tjenester';
import Footer from './components/footer';
import Gallery from './components/gallery';

function App() {
  return (
    <div className="App">
      <Top />
      <Tjenester />
      <Gallery />
      <Footer />
    </div>
  );
}

export default App;
